<template>
  <div class="search_popuparea">
    <div
      class="modal"
      id="searchmodal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              <img class="img-fluid" src="assets/img/logo.png" alt="logo" />
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="titelbox">{{ $t("search-result.search") }}</div>
          <div class="modal-body">
            <div class="card card-body boxSideSearch border-0">
              <form action="">
                <div class="form-row">
                  <div class="form-group col-12">
                    <span
                      >{{ $t("search-tab.mobile-form.destination-label") }}:</span
                    >
                    <input
                      type="search"
                      class="form-control"
                      id="serchItem"
                      value="Goa"
                    />
                  </div>
                  <div class="form-group col-12">
                    <label>{{
                      $t("search-tab.mobile-form.start-date")
                    }}</label>
                    <div class="input-group">
                      <input
                        id="startDate"
                        type="text"
                        class="form-control datepicker-start border-right-0"
                        placeholder="Choose Check-in"
                      />
                      <div class="input-group-append">
                        <span class="input-group-text bg-transparent">
                          <i class="far fa-calendar-alt"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-12">
                    <label for="endtDate">{{
                      $t("search-tab.mobile-form.end-date")
                    }}</label>
                    <div class="input-group">
                      <input
                        id="endtDate"
                        type="text"
                        class="form-control datepicker-end border-right-0"
                        placeholder="Choose Check-in"
                      />
                      <div class="input-group-append">
                        <span class="input-group-text bg-transparent">
                          <i class="far fa-calendar-alt"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-12">
                    <label for="adultPertion"
                      >3 {{ $t("search-tab.mobile-form.night-stay") }}</label
                    >
                    <select class="form-control" id="adultPertion">
                      <option>1 {{ $t("search-tab.adult") }}</option>
                      <option selected="" disabled="">
                        2 {{ $t("search-tab.adults") }}
                      </option>
                      <option>3 {{ $t("search-tab.adults") }}</option>
                      <option>4 {{ $t("search-tab.adults") }}</option>
                      <option>5 {{ $t("search-tab.adults") }}</option>
                    </select>
                  </div>
                  <div class="form-group col-6">
                    <select class="form-control">
                      <option selected="" disabled="">
                        1 {{ $t("search-tab.mobile-form.night-stay") }}
                      </option>
                      <option>2 {{ $t("search-tab.mobile-form.night-stay") }}</option>
                      <option>3 {{ $t("search-tab.mobile-form.night-stay") }}</option>
                      <option>4 {{ $t("search-tab.mobile-form.night-stay") }}</option>
                    </select>
                  </div>
                  <div class="form-group col-6">
                    <select class="form-control">
                      <option selected="" disabled="">
                        1 {{ $t("search-tab.mobile-form.room") }}
                      </option>
                      <option>2 {{ $t("search-tab.mobile-form.room") }}</option>
                      <option>3 {{ $t("search-tab.mobile-form.room") }}</option>
                      <option>4 {{ $t("search-tab.mobile-form.room") }}</option>
                    </select>
                  </div>
                  <div class="form-group col-12">
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="travelWork"
                      />
                      <label class="custom-control-label" for="travelWork">{{
                        $t("search-tab.mobile-form.check-label")
                      }}</label>
                    </div>
                  </div>
                  <div class="form-group col-6 d-flex">
                    <button class="btn flex-fill btn-outline-dark btn-search">
                      {{ $t("search-tab.search") }}
                      <i class="fas fa-search"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div
      class="modal"
      id="filtercont"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              <img class="img-fluid" src="assets/img/logo.png" alt="logo" />
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="titelbox">{{ $t("search-result.list-view") }}</div>
          <div class="modal-body">
            <ul class="list-inline propFilter-top">
              <li class="list-inline-item">
                <input type="checkbox" class="custom-control-input" id="fltrEverything" />
                <label for="fltrEverything">
                  <span>{{ $t("search-result.everything") }}</span>
                </label>
              </li>
              <li class="list-inline-item">
                <input type="checkbox" class="custom-control-input" id="fltrPrice" />
                <label for="fltrPrice">
                  <span>{{ $t("search-result.price") }}</span>
                </label>
              </li>
              <li class="list-inline-item">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="fltrTripDuration"
                />
                <label for="fltrTripDuration">
                  <span>{{ $t("search-result.duration-trip") }}</span>
                </label>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div
      class="modal"
      id="filterbox"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              <img class="img-fluid" src="assets/img/logo.png" alt="logo" />
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="titelbox">{{ $t("search-result.filter") }}</div>
          <div class="modal-body">
            <div class="accordion sidePanel" id="accordionParent">
              <div class="card">
                <div class="card-header" id="headingStarRating">
                  <h2 class="mb-0">
                    <button
                      class="btn btn-link"
                      type="button"
                      data-toggle="collapse"
                      data-target="#starRating"
                      aria-expanded="false"
                      aria-controls="starRating"
                    >
                      <!-- collapsed -->
                      {{ $t("search-result.one-trip-found") }}
                    </button>
                  </h2>
                </div>
                <div
                  id="starRating"
                  class="collapse show"
                  aria-labelledby="headingStarRating"
                  data-parent=""
                >
                  <div class="card-body">
                    <ul class="list-group list-group-flush filter-hotel">
                      <li class="list-group-item">
                        <div class="custom-control custom-radio">
                          <input
                            type="radio"
                            class="custom-control-input"
                            id="radioDayOne"
                            name="radio-stacked"
                            required
                          />
                          <label class="custom-control-label" for="radioDayOne">
                            <span>{{ $t("search-result.recommended") }}</span>
                            <span class="text-muted mr-3">$1199</span>
                          </label>
                        </div>
                      </li>
                      <li class="list-group-item">
                        <div class="custom-control custom-radio">
                          <input
                            type="radio"
                            class="custom-control-input"
                            id="radioDayTwo"
                            name="radio-stacked"
                            required
                          />
                          <label class="custom-control-label" for="radioDayTwo">
                            <span>{{ $t("search-result.special") }}</span>
                            <span class="text-muted mr-3">$999</span>
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchPopup',
  components: {},
  props: {
    msg: String,
  },
};
</script>
